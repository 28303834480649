.carousel .slide{ 
  background: transparent;
  padding: 20px 0px;
}

.carousel .control-dots {
  z-index: 10;
}

.carousel .control-dots .dot {
  box-shadow: none;
}

.carousel-root {
  width: 100%;
}