.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.particles-wrapper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.private-container {
  min-height: 100vh;
  width: 100%;
}

#iphone::before {
  content: '';
  width: 150px;
  height: 25px;
  background-color: #222121;
  background-image: url('./phone-mic.png');
  background-position: 50% 200%;
  background-repeat: no-repeat;
  background-size: 80%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 70px 70px;
}

#iphone {
  width: 320px;
  border: 220px solid #222121;
  margin: auto;
  overflow: hidden;
  box-shadow: 0px 3px 0 #494350, 0px 4px 0 #494350, 0px 5px 0 #494350, 0px 7px 0 #494350,
    0px 10px 20px #000;
  border-width: 15px 17px;
  border-radius: 40px;
}

#iphone div {
  background-color: #222121;
}

#preview-iframe {
  border: 0;
  overflow: hidden;
  max-width: 1064px;
}

body {
  padding-right: 0px !important;
}

.noscroll { 
  overflow: hidden;
}

.kimple-loader {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
}

/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}